import consumer_orderNo_center_orderList_download from '@/lib/data-service/haolv-default/consumer_orderNo_center_orderList_download'

export default {
    data() {
        return {
            minDateBox: null,
            pickerOptions: {
                onPick: ({ maxDate, minDate }) => {
                    if (minDate) {
                        this.minDateBox = null;
                        this.minDateBox = this.$moment(minDate).format('YYYY-MM-DD');
                    }
                },
                disabledDate: time => {
                    const maxDate = this.$moment(this.minDateBox).add(6,'months')._d;
                    const minDate = this.$moment(this.minDateBox).add(-6,'months')._d;
                    return this.minDateBox ? time.getTime() > maxDate || time.getTime() < minDate: false;
                }
            },
            dialogLoading: false,
            dialogVisible: false,
            form: {
                datePicker: null,
            },
            rules: {
                datePicker: [
                    { required: true, message: '请选择下单日期', trigger: 'change' }
                ],
            },
        }
    },
    props: {
        thirdLevelMenuList: {
            type: Array,
            default: () => []
        }
    },
    components: {},
    created() {
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        closeDatePicker() {
            this.$refs.form.resetFields();
        },
        downloadClick() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    let box = {
                        startDateTime: this.form.datePicker[0] + ' 00:00:00',
                        endDateTime: this.form.datePicker[1] + ' 23:59:59',
                    };
                    this.dialogLoading = true;
                    consumer_orderNo_center_orderList_download(box).then(res => {
                        let url = res.datas.orderDetailsUrl;
                        if (!url && url === '') {
                            this.$message({
                                type: "warning",
                                message: '下载失败，请稍候再试'
                            });
                            return
                        }
                        this.dialogVisible = false;
                        window.open(url, "_blank");
                    }).finally(() => {
                        this.dialogLoading = false;
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
            // this.dialogVisible = false;
        },
        downloadDialogClick() {
            this.minDateBox = null;
            this.form.datePicker = null;
            this.dialogVisible = true;
            this.$nextTick(() => {
                this.$refs.form.resetFields();
            });
        },
        jumpNext(val) {
            let routeName = '';
            if (val.routeName) {
                routeName = val.routeName
            } else {
                routeName = val.children[0].routeName
            }
            this.$router.push({
                name: routeName
            })
        }
    }
}