import Header from './component/header/index.vue';
import LeftMenu from './component/left-menu/index.vue'
import Menu from './component/menu/index.vue'
import Footer from './component/footer/1.0.2/index.vue'
import MenuTab from './component/menu-tab/index.vue'
import OrderCenterFirstMenu from './component/order-center-first-menu/index.vue'
import OrderCenterSecondMenu from './component/order-center-second-menu/index.vue'
import getMenuData from './menuList'
import consumer_menu_getUserMenu from '@/lib/data-service/haolv-default/consumer_menu_getUserMenu'
import get_consumer_menu_getUserMenu from '@/lib/common-service/get_consumer_menu_getUserMenu'
import get_user_info from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info' // 获取个人信息
import centerMenu from "./center-menu";
import check_token_processor from '@/router/init_router/lib/check_token_processor';
import wangyiqiyu from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/wangyiqiyu_helper/index.js'
import consumer_t_tp_refund_change_policy_get
  from '@/lib/data-service/haolv-default/consumer_t-tp-refund-change-policy_get' // 获取出差规则信息
import consumer_web_staff_getButtonMenu from '@/lib/data-service/haolv-default/consumer_web_staff_getButtonMenu'
export default {
    data() {
        return {
            // oriNavList: [],
            // navList: [],
            secondLevelMenuList: [],
            thirdLevelMenuList: [],
            fourthLevelMenuList: []
        }
    },
    components: {
        Header,
        LeftMenu,
        Menu,
        Footer,
        MenuTab,
        OrderCenterFirstMenu,
        OrderCenterSecondMenu
    },
    async created() {
        // this.oriNavList = getMenuData() // 本地菜单
        // this.$store.commit('setOriNavList', this.oriNavList)
        this.getBtn(); // 获取员工按钮权限
        await this.getUserMenu() // 请求菜单
        this.getMenuList()
    },
    mounted() {
        this.$store.dispatch('updateCompanyInfo');
        check_token_processor.get_login_completed_promise().then(function () {
            wangyiqiyu.config().then(() => {
                wangyiqiyu.show()
            })
        });
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {
        $route() {
            this.getMenuList()
        },
        navList(newVal) {
            this.setMetaList() // 分割成左侧菜单和三级菜单的分别显示
        },
    },
    computed: {
        navList() {
            return this.$store.state.navList
        },
        oriNavList() {
            return this.$store.state.oriNavList
        },
        layoutType() {
            let meta = JSON.parse(JSON.stringify(this.$route.meta))
            if (meta.data_for_back_layout) {
                return meta.data_for_back_layout.layoutType || ''
            } else {
                return ''
            }

        },
        lastMenu() {
            return this.$route.meta.lastMenu || this.$route.meta.data_for_back_layout.lastMenu || ''
        },
        setBg() {
            let routeName = this.$route.name
            if (routeName === 'admin-travel-book-home' || routeName === 'admin-travel-book-home-flight' || routeName === 'admin-travel-book-home-hotel' || routeName === 'admin-travel-book-home-train') {
                // 当前页面为行程预定的首页
                return 'home'
            } else if (routeName === 'admin-train-search' || routeName === 'admin-train-change-search') {
                // 当前页是火车票搜索页
                return 'train'
            } else if (routeName === 'admin-flight-search') {
                // 当前页是机票搜索页
                return 'flight'
            } else if (routeName === 'admin-hotel-search') {
                return 'hotel'
            } else {
                return ''
            }
        },
    },
    filters: {},
    methods: {
        getBtn() {
            consumer_web_staff_getButtonMenu().then(res=>{
                let list = [];
                if (res.datas.menuList) {
                    res.datas.menuList.forEach(value => {
                        list.push(value.url);
                    })
                }
                this.$store.commit('setBtnList', list);
            });
        },
        async getUserMenu() {
            // 多维数组对象转一位数组对象
            function flatten(arr) {
                return [].concat(...arr.map(item => [].concat(item, ...flatten(item.menus || []))))
            }
            let res001 = await consumer_t_tp_refund_change_policy_get();
            let res = await get_consumer_menu_getUserMenu({clear_cache: true}); // 如果不传入{clear_cache: true}则菜单不完整
            if (res001.datas.showSubsidyControlRule != 1) {
                res.datas.forEach((item) => {
                    if (item.id == 7) {
                        item.menus.forEach((Mitem) => {
                            if (Mitem.id == 78) {
                                Mitem.menus = Mitem.menus.filter(item => item.id !== 788);
                            }
                        });
                    }
                });
            }
            res.datas[0].menus = []; // 删除行程预定里面的
            res.datas.push(centerMenu);
            let oriNavList = JSON.parse(JSON.stringify(res.datas));
            oriNavList = flatten(oriNavList); // 转成1维数组
            /*for(let item of oriNavList) { // 删除menus
                if(item.menus) {
                    delete item.menus
                }
            }*/
            let oriResultNavList = [];
            oriNavList.forEach((value, index) => {
                if (value.menus) {
                    delete value.menus
                }
                // 暂时屏蔽功能导航
                // if (value.name !== '功能导航') {
                //     oriResultNavList.push(value)
                // }
                oriResultNavList.push(value)
            })
            // this.oriNavList = oriResultNavList // 保存到原始菜单数组
            this.$store.commit('setOriNavList', oriResultNavList)
        },
        getMenuList() {
            let navList = JSON.parse(JSON.stringify(this.oriNavList)); // 深拷贝原始数据
            navList = this.setActive(navList); // 获取当前页面的菜单高亮以及parent的高亮
            navList = this.translateDataToTree(navList); // 组装多维数组
            this.$store.commit('setNavList', navList);
            this.setMetaList() // 分割成左侧菜单和三级菜单的分别显示
        },
        setActive(dataList) {
            let activeId = '';
            dataList.forEach(value => {
                if (value.metaName === this.lastMenu) {
                    value.active = true
                    activeId = value.parentId
                } else {
                    value.active = false
                }
            });
            let getParentActive = function (parentId) {
                if (parentId === 0) {
                    return
                }
                dataList.forEach(value => {
                    if (value.id === parentId) {
                        value.active = true;
                        getParentActive(value.parentId)
                    }
                })
            };
            getParentActive(activeId);

            /*dataList.forEach(value => {
                let hasActive = activeList.indexOf(value.metaName)
                if (hasActive >= 0) {
                    value.active = true
                } else {
                    value.active = false
                }
            })*/
            // 排序
            dataList.sort((a, b) => {
                return (a.orderNum || 999) - (b.orderNum || 999)
            })
            return dataList
        },
        translateDataToTree(data) {
            const parents = data.filter(value => value.id && (value.parentId === '0' || value.parentId === 0 || value.parentId === undefined || value.parentId === null)) // 没有父节点的数据
            const children = data.filter(value => value.id && (value.parentId !== '0' || value.parentId !== 0)) // 有父节点的数据
            // 定义转换方法的具体实现
            const translator = (parents, children) => {
                parents.forEach((parent) => {
                        children.forEach((current, index) => {
                                if (current.parentId === parent.id) {
                                    const temp = JSON.parse(JSON.stringify(children))
                                    temp.splice(index, 1)
                                    translator([current], temp)
                                    typeof parent.children !== 'undefined' ? parent.children.push(current) : parent.children = [current]
                                }
                            }
                        )
                    }
                )
            }
            translator(parents, children)
            return parents
        },
        setMetaList() {
            for (let i = 0, l = this.$store.state.navList.length; i < l; i++) {
                if (this.$store.state.navList[i].active) {
                    this.secondLevelMenuList = this.navList[i].children ? this.navList[i].children : []
                    if (this.navList[i].children) {
                        for (let m = 0, n = this.navList[i].children.length; m < n; m++) {
                            if (this.navList[i].children[m].active) {
                                this.thirdLevelMenuList = this.navList[i].children[m].children ? this.navList[i].children[m].children : []
                                if (this.navList[i].children[m].children) {
                                    for (let p = 0, q = this.navList[i].children[m].children.length; p < q; p++) {
                                        if (this.navList[i].children[m].children[p].active) {
                                            this.fourthLevelMenuList = this.navList[i].children[m].children[p].children ? this.navList[i].children[m].children[p].children : []
                                            break
                                        }
                                    }
                                }
                                break
                            } else {
                                this.fourthLevelMenuList = []
                            }
                        }
                    } else {
                        this.thirdLevelMenuList = []
                        this.fourthLevelMenuList = []
                    }
                    break
                }
            }
        }
    }
}
